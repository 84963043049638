// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'
import { useUser } from '@auth0/nextjs-auth0'

// ** Hook Imports

/**
 *  Set Home URL based on User Roles
 */
export const getHomeRoute = (role: string) => {
  if (role === 'client') return '/acl'
  else return '/dashboards/crm'
}

const Home = () => {
  // ** Hooks
  const { user } = useUser()

  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (user && user.role) {
      const homeRoute = getHomeRoute(user.role as string)

      // Redirect user to Home URL
      router.replace(homeRoute)
    } else if (user) {
      router.replace('/customers/list')
    }
    // we only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner text='Evaluating user roles...' />
}

export default Home
